.choosenService {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
  &__block {
    display: flex;
    flex-direction: row;
    gap: 96px;
    margin-top: 30px;
  }
  &__image-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__image-container {
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    display: flex;
    height: 300px;
    justify-content: center;
    width: 300px;
  }
  &__image {
    display: block;
    height: 220px;
    width: 220px;
  }
  &__share-buttons-block {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
  &__text-block {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-family: "Cormorant Garamond", serif;
    font-size: 48px;
    font-weight: 500;
    margin: 0;
  }
  &__time {
    font-family: "Cormorant Garamond", serif;
    font-size: 30px;
    font-weight: 300;
    margin: 0;
  }
  &__description {
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  &__floor {
    font-family: Cormorant Garamond,serif;
    font-size: 30px;
    font-weight: 300;
    margin: 0 0 48px;
  }
  &__buttons-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  &__button-black {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 60px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: #ffffff;
    color: #000000;
    transition: all 0.5s;
    text-decoration: none;
    margin-top: auto;

    &:hover {
      cursor: pointer;
      background-color: black;
      color: #ffffff;
    }
  }

  &__button-white {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 60px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: #000000;
    color: #ffffff;
    transition: all 0.5s;
    text-decoration: none;
    margin-top: auto;

    &:hover {
      cursor: pointer;
      background-color: #ffffff;
      color: #000000;
    }

  }
}

@media screen and (max-width: 990px) {
  .choosenService {
    &__block {
      flex-direction: column;
      align-items: center;
    }
    &__text-block {
      align-items: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .choosenService {
    &__block {
      gap: 30px;
    }
    &__buttons-block {
      flex-direction: column;
    }
  }
}
