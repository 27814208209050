.photoGallery {
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 5%;

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  &__link {
    margin-right: 5%;
    font-family: 'Cormorant Garamond', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    color: #000000;

    &:hover {
      opacity: 0.5;
    }
  }

  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    text-align: left;
    margin: 0;
  }
}

@media screen and (max-width: 1500px) {
  .photoGallery {
    &__title {
      font-size: 48px;
      line-height: 58px;
    }
    &__link {
      font-size: 30px;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: 700px) {
  .photoGallery {
    &__link {
      display: block;
      text-align: center;
      margin-bottom: 150px;
    }
  }
}
