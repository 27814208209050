.tgPopupPreview {
  width: 100%;
  height: 60px;
  background-color: #0057B8;
  color: #ffffff;
  position: sticky;
  bottom: 0;
  z-index: 15;
  font-family: "Raleway", serif;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}