.shopListAlphabet {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 100px 46px;
}

.shopList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 100px 46px;
  margin-bottom: 50px;
  &__container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .shopList {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }
}

