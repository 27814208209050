.questions {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__block {
    border: 1px solid rgba(0, 0, 0, 1);
    padding: 22px 26px 24px 20px;
    cursor: pointer;
  }
  &__question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__question-text {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    margin: 0;
  }
  &__arrow {
    transition: transform 0.5s linear;
    &_active {
      transform: rotate(180deg);
      color: #ffffff;
    }
  }
  &__answer-text {
    margin-top: 20px;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 200%;
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transition: opacity 0.5s linear, max-height 0.5s linear;
    &_active {
      opacity: 1;
      max-height: 500px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .questions {
    &__block {
      padding: 22px 20px;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 1);
    }
    &__question {
      gap: 5px;
    }
    &__question-text {
      font-size: 18px;
      line-height: 22px;
    }
    &__answer-text {
      font-size: 14px;
      line-height: 16px;
    }
  }
}