.promotionsGrid {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 100px;
    row-gap: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  &__card {
    max-width: 460px;
  }
  &__card-time {
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;
    margin-top: 20px;
  }
  &__card-title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
    margin-top: 7px;
    margin-bottom: 31px;
  }
  &__card-image {
    width: 460px;
    height: 460px;
  }
  &__button {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 220px;
    height: 60px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: #ffffff;
    color: #000000;
    transition: all 0.5s;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      background-color: black;
      color: #ffffff;
    }
  }

  &__button-archive {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 300px;
    height: 60px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin: 80px auto 0;
    color: #ffffff;
    background-color: #000000;
    transition: all 0.5s;
    &:hover {
      cursor: pointer;
      background-color: #ffffff;
      color: #000000;
    }
  }
}

@media screen and (max-width: 1700px) {
  .promotionsGrid {
    &__card {
      max-width: 300px;
    }
    &__card-image {
      width: 300px;
      height: 300px;
    }
    &__card-title {
      font-size: 18px;
      line-height: 22px;
    }
    &__card-time {
      font-size: 12px;
      line-height: 14px;
    }
  }
}



