.choosenGallery {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 200px;
  }
  &__galleryInfo {
    margin-top: 300px;
  }
  &__galleryDate {
    margin: 0;
    font-family: "Cormorant Garamond", serif;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
  }
  &__galleryName {
    margin: 10px 0 0 0;
    font-family: "Cormorant Garamond", serif;
    font-weight: 300;
    font-size: 64px;
    line-height: 78px;
  }
  &__galleryInfo {
    display: flex;
    flex-direction: column;
  }
  &__swiperContainer {
    display: block;
  }
  &__mainSwiper {
    width: 1100px;
    height: 825px;
  }
  &__miniSwiper {
    margin-top: 20px;
    width: 1100px;
    height: 166px;
  }
  &__mainSwiperSlide {
    max-width: 1100px;
  }
  &__otherGalleriesTitle {
    margin: 0 0 80px 0;
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.choosenGallery__miniSwiper .swiper-slide {
  max-width: 25%;
  height: 100%;
  opacity: 0.4;
}

.choosenGallery__miniSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

@media screen and (max-width: 1650px) {
  .choosenGallery {
    &__gallery {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      margin-bottom: 150px;
    }
    &__galleryInfo {
      margin-top: 50px;
    }
    &__galleryDate {
      font-size: 30px;
      line-height: 36px;
    }
    &__galleryName {
      font-size: 48px;
      line-height: 58px;
    }
  }
}

