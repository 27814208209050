.swiperChoosenGallery {
  width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__mainSwiper {
    width: 100%;
  }
  &__mainSwiperSlide {
    width: 100%;
    height: 825px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__navigation {
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    top: 50%;
  }

  &__prev {
    left: 20px;
    background-image: url("../../vendor/img/arrow-slider-left.svg");
  }

  &__next {
    right: 20px;
    background-image: url("../../vendor/img/arrow-slider-right.svg");
  }
  &__miniSwiper {
    width: 100%;
    height: 166px;
    & .swiper-slide-thumb-active {
      opacity: 1;
    }
  }
  &__miniSwiperSlide {
    opacity: 0.5;
  }
}
