.signEmail {
  background-color: #F0F0F0;
  align-items: center;
  padding-top: 150px;
  min-width: 780px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 51px;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../../vendor/img/footer-left.svg"),
  url("../../vendor/img/footer-right.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: bottom left, top right;
  &_shop {
    position: relative;
    z-index: 3;
  }

  &__title {
    font-family: Raleway, serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 30px;
    text-transform: uppercase;
    display: block;
  }
  &__link {
    font-family: Raleway, serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-top: -120px;
    margin-bottom: 100px;
    color: #03286B;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
  &__main-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
  }

  &__input {
    width: 480px;
    display: inline-block;
    height: 60px;
    text-align: center;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    color: #00000080;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
  }



  &__button {
    width: 300px;
    height: 60px;
    background-color: #000000;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    transition: all 0.5s ease;
    padding: 0;
    margin: 0;
    &:hover {
      background-color: #ffffff;
      color: #000000;
      cursor: pointer;
    }
  }

  &__description {
    font-family: 'Raleway', serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 36px;
    color: #000000;
    margin: 0;
  }
  &__feedback-fail {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    display: none;
    color: red;
  }
  &__copyright {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 110px;
    background-color: #ffffff;
    margin-right: auto;
    margin-left: auto;
  }
  &__feedback-success {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    display: none;
    color: green;
    &_visible {
      display: block;
    }
  }
}

@media screen and (max-width: 1260px) {
  .signEmail {
    &__link {
      font-size: 18px;
      line-height: 22px;
      margin-top: -100px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
}

@media screen and (max-width: 840px) {
  .signEmail {
    min-width: 0;
    &__wrapper {
      max-width: 300px;
    }
    &__main-container {
      flex-direction: column;
      gap: 20px;
    }
    &__input {
      width: 300px;
    }
    &__description {
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }
}






