.swiperPhotoGallery {
  font-family: "Cormorant Garamond", serif;
  &__slide {
    display: flex;
    flex-direction: column;
    width: 1020px;
  }
  &__images-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 525px;
  }
  &__main-image {
    width: 700px;
    height: 525px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__other-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  &__other-image {
    width: 300px;
    height: 225px;
    flex-shrink: 0;
  }
  &__other-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__description-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 10px;
  }
  &__title {
    margin: 0;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    max-width: 700px;
  }
  &__date {
    margin: 0;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 60px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: #ffffff;
    color: #000000;
    transition: all 0.5s;
    text-decoration: none;
    margin-top: auto;

    &:hover {
      cursor: pointer;
      background-color: black;
      color: #ffffff;
    }

  }
  &__navigation {
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    top: 270px;
  }

  &__prev {
    left: 20px;
    background-image: url("../../vendor/img/arrow-slider-left.svg");
  }

  &__next {
    right: 20px;
    background-image: url("../../vendor/img/arrow-slider-right.svg");
  }
}

@media screen and (max-width: 1500px) {
  .swiperPhotoGallery {
    &__slide {
      width: 620px;
    }
    &__images-container {
      width: 620px;
      height: 345px;
    }
    &__main-image {
      width: 460px;
      height: 345px;
    }
    &__other-image {
      width: 140px;
      height: 100px;
    }
    &__title {
      font-size: 18px;
      line-height: 22px;
    }
    &__date {
      font-size: 30px;
      line-height: 36px;
    }
    &__navigation {
      top: 150px;
    }
  }
}

@media screen and (max-width: 700px) {
  .swiperPhotoGallery {
    margin-bottom: 40px;
    &__slide {
      width: 300px;
    }
    &__images-container {
      flex-direction: column;
      width: 300px;
      height: 459px;
    }

    &__main-image {
      width: 300px;
      height: 225px;
    }
    &__other-images {
      flex-direction: row;
      width: 100%;
    }
    &__other-image {
      width: 220px;
      height: 165px;
    }
    &__description-container {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
      margin-bottom: 78px;
    }
    &__link {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
