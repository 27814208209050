.uslugiPage {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    text-align: left;
  }
}

.uslugiGrid {
  max-width: 1740px;
  margin: 50px auto 130px;
  &__container {
    width: 100%;
    list-style: none;
    justify-content: center;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, 220px);
    gap: 75px;
  }
  &__element {
    max-width: 220px;
  }
  &__image-container {
    width: 220px;
    height: 220px;
    border: 1px solid #000000;
  }
  &__image {
    width: 100px;
    height: 100px;
    margin: 60px;
  }
  &__description {
    font-size: 25px;
    line-height: 30px;
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    margin-top: 16px;
  }
}

