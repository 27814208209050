.info {
  margin-top: 50px;
  &__information {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__information-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  &__key {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
    margin: 0;
  }
  &__value {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }
  &__title {
    margin-top: 80px;
    font-family: "Cormorant Garamond", serif;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
  }
  &__last-purchases {
    display: flex;
    flex-direction: column;
  }
  &__last-purchases-title {
    display: grid;
    grid-template-columns: 220px auto 220px;
    justify-items: center;
    max-width: 780px;
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
  }
  &__last-purchases-item {
    display: grid;
    grid-template-columns: 220px auto 220px;
    justify-items: center;
    max-width: 780px;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(240, 240, 240, 1);
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
}

@media screen and (max-width: 1200px) {
  .info {
    &__information {
      gap: 10px;
    }
    &__key {
      font-size: 18px;
      line-height: 22px;
    }
    &__value {
      font-size: 14px;
      line-height: 18px;
    }
    &__title {
      margin: 50px 0 0;
      font-size: 30px;
      line-height: 36px;
    }
    &__last-purchases-title {
      grid-template-columns: 100px auto 80px;
      font-size: 16px;
      line-height: 19px;
    }
    &__last-purchases-item {
      grid-template-columns: 100px auto 80px;
      padding-top: 30px;
      padding-bottom: 10px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}