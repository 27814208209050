.footer {
  display: flex;
  flex-direction: row;
  background-color: #F0F0F0;
  justify-content: space-evenly;
  &_shop {
    position: relative;
    z-index: 3;
  }

  &__block {
    display: flex;
    flex-direction: column;
    max-width: 300px;
  }
  &__link-text {
    margin: 0;
  }

  &__title {
    font-family: Raleway, serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  &__images-container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 30px;
  }

  &__link-image {
    width: 20px;
    height: 20px;
    display: block;
    &:hover {
      opacity: 0.5;
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
  }

  &__link {
    font-family: Raleway, serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
    text-decoration: none;
    color: #000000;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1400px) {
  .footer {
    &__block {
      max-width: 200px;
    }
  }
}


