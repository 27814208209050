.swiperMain {
  width: 70%;
  height: auto;
  margin-left: 0;
  margin-right: 0;
  &__link {
    width: 100%;
    height: 100%;
    display: block;
  }
  &__image {
    width: 100%;
    height: 100%;
    display: block;
  }
  &__navigation {
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    right: 90px;
  }

  &__prev {
    bottom: 70px;
    background-image: url("../../vendor/img/arrow-slider-left.svg");
  }

  &__next {
    bottom: 130px;
    background-image: url("../../vendor/img/arrow-slider-right.svg");
  }

  &__pagination-container {
    height: 8px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    right: 90px !important;
    bottom: 42px !important;
    left: auto !important;
    width: fit-content !important;
  }

  &__pagination-bullet {
    height: 100%;
    width: 8px;
    border-radius: 4px;
    display: block;
    background: rgba(255, 255, 255, 0.50);
  }

  &__pagination-bullet-active {
    width: 24px;
    background-color: #ffffff;
  }
}


@media screen and (max-width: 1919px) {
  .swiperMain {
    height: auto;
    &__navigation {
      right: 60px;
    }
    &__prev {
      bottom: 48px;
    }

    &__next {
      bottom: 108px;
    }
    &__pagination-container {
      bottom: 20px !important;
      right: 60px !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .swiperMain {
    width: 100%;
    &__navigation {
      display: none;
    }
    &__pagination-container {
      bottom: 20px !important;
      left: 50% !important;
      transform: translate(-50%);
    }
  }
}
