.navigate {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: center;

  &__map {
    width: 100%;
    height: 700px;
  }
  &__bread-crumbs {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;
  }
}