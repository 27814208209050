.myProfile {
  max-width: 57.29%;
  margin: 50px auto 130px;
  &__bread-crumbs {
    margin-left: 5%;
    margin-right: 5%;
  }
  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    color: #000000;
  }
  &__links {
    display: inline-flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 1)
  }
  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Cormorant Garamond", serif;
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    &_active {
      @extend .myProfile__tab;
      border: 1px solid rgba(0, 0, 0, 1);
      background-image: url("./../../vendor/img/background-active-link.svg");
      border-bottom: none;
    }
  }
  &__footer {
    margin-top: 80px;
  }
  &__footer-title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    margin: 0;
  }
  &__footer-text {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
  &__footer-contacts-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  &__footer-item {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    & p {
      margin: 0;
    }
  }
  &__footer-link {
    height: 24px;
    &:hover {
      opacity: 0.5;
    }
  }
}

@media (max-width: 978px) {
  .myProfile {
    max-width: 94.44%;
    margin: 50px auto 100px;
    &__title {
      font-size: 48px;
      line-height: 58px;
      margin-top: 50px;
      margin-bottom: 30px;
      text-align: center;
    }
    &__links {
      display: flex;
      flex-direction: column;
      max-width: 83.33%;
      margin-left: auto;
      margin-right: auto;
      border-bottom: none;
    }
    &__tab {
      border-bottom: 1px solid rgba(0, 0, 0, 1);
      &_active {
        @extend .myProfile__tab;
        border: 1px solid rgba(0, 0, 0, 1);
        background-image: url("./../../vendor/img/background-active-link.svg");
      }
    }
    &__footer {
      margin-top: 50px;
      max-width: 83.33%;
      margin-left: auto;
      margin-right: auto;
    }
    &__footer-title {
      font-size: 18px;
      line-height: 22px;
    }
    &__footer-contacts-block {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      margin-bottom: 20px;
    }
    &__footer-item {
      font-size: 14px;
      line-height: 16px;
    }
    &__footer-text {
      font-size: 12px;
      line-height: 14px;
    }

  }
}