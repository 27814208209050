.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s ease-in;
  &_opened {
    visibility: visible;
    opacity: 1;
  }
  &__button {
    width: 50px;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
  }
  &__input-block {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    width: 100%;
  }
  &__input {
    font-family: Raleway, serif;
    font-weight: 500;
    height: 100%;
    width: 100%;
    border: 0;
    outline: none;
  }
  &__results {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__result-notfound {
    font-family: "Cormorant Garamond", serif;
    margin: 0;
    font-weight: 700;
    color: #000000;
    font-size: 20px;
    border-bottom: 1px solid rgb(240, 241, 241);
    background-color: rgb(247, 247, 247);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__result-title {
    font-family: "Cormorant Garamond", serif;
    margin: 0;
    padding-left: 5%;
    font-weight: 700;
    color: #000000;
    font-size: 20px;
    border-bottom: 1px solid rgb(240, 241, 241);
    background-color: rgb(247, 247, 247);
    height: 40px;
    display: flex;
    align-items: center;
  }
  &__result-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__result-list-item {
    height: 55px;
    border-bottom: 1px solid rgb(240, 241, 241);
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    &:hover {
      background-color: #f7f7f7;
    }
  }
  &__result-item-link {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    text-decoration: none;
    align-items: center;
  }
  &__result-item-img {
    height: 54px;
    width: 54px;
    margin-left: 20px;
    margin-right: 20%;
  }
  &__result-item-text-block {
    display: flex;
    flex-direction: column;
  }
  &__result-item-title {
    margin: 0;
    padding: 0;
    font-family: "Cormorant Garamond", serif;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
  }
  &__result-item-tags {
    margin: 0;
    padding: 0;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    text-decoration: none;
  }
  &__result-item-floor {
    margin-right: 5%;
    margin-left: auto;
    padding: 0;
    font-family: "Cormorant Garamond", serif;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
  }
}

@media screen and (max-width: 1525px) {
  .search {
    &__result-item-img {
      margin-right: 10%;
    }
  }
}

@media screen and (max-width: 1240px) {
  .search {
    &__result-item-img {
      margin-right: 5%;
    }
  }
}

@media screen and (max-width: 1050px) {
  .search {
    &__block {
      width: 90%;
    }
  }
}

@media screen and (max-width: 640px) {
  .search {
    &__result-item-img {
      margin-left: 10px;
    }
    &__result-item-title {
      font-size: 14px;
    }
    &__result-item-tags {
      font-size: 12px;
    }
    &__result-item-floor {
      font-size: 14px;
    }
  }
}
