@import url(./vendor/fonts.scss);
@import url(./vendor/normalize.scss);

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
    line-height: 1.5;
}

