.headerMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  padding: 15px 5%;
  border-bottom: 2px solid #f0f0f0;
}

.headerMobile_shop {
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 6;
}
