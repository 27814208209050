.shopsListAlphabetBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  position: relative;

  &__title {
    position: absolute;
    left: -50px;
    top: -64px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    margin: 0;
  }
}

@media screen and (max-width: 849px){
  .shopsListAlphabetBlock {
    flex-direction: column;
    &__title {
      left: 0;
      top: -80px;
    }
  }
}