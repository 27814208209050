@font-face {
  font-family: VolkornSCBold;
  src: url(./assets/fonts/VollkornSC-Bold.ttf);
}

@font-face {
  font-family: VolkornSCMedium;
  src: url(./assets/fonts/VollkornSC-Medium.ttf);
}

@font-face {
  font-family: AeroportMedium;
  src: url(./assets/fonts/Aeroport-Medium.ttf);
}

@font-face {
  font-family: AeroportLight;
  src: url(./assets/fonts/Aeroport-Light.ttf);
}

.spring-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;

  a {
    color: inherit;
  }

  h1 {
    font-size: 160px;
    margin: 0;
    font-weight: 400;
  }

  h2 {
    font-family: VolkornSCBold;
    text-transform: uppercase;
    text-align: center;
    font-size: 120px;
    margin-top: 230px;
  }

  @media (max-width: 1400px) {
    h1 {
      font-size: 125px;
    }

    h2 {
      font-size: 88px;
      margin-top: 90px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 992px) {
    h1 {
      font-size: 105px;
    }
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 75px;
    }

    h2 {
      font-size: 58px;
      margin-top: 50px;
    }
  }

  @media (max-width: 520px) {
    h1 {
      font-size: 50px;
    }

    h2 {
      font-size: 38px;
      margin-top: 50px;
    }
  }
}

.spring-scrollable {
  background-image: url(./assets/background.webp);
  padding-top: 160px;
  background-size: cover;
  background-position: center top;
  color: #fff;
  text-shadow: 5px 7px 12px rgba(77, 109, 150, 0.72);
  line-height: 1;
  font-family: AeroportMedium;

  @media (max-width: 1400px) {
    padding-top: 120px;
  }

  @media (max-width: 992px) {
    padding-top: 60px;
  }

  @media (max-width: 767px) {
    background-image: url(./assets/background_mob.jpg);
  }
}

.title-container {
  margin-left: 146px;
  font-family: VolkornSCMedium;

  .title-small-text {
    font-size: 22px;
    margin: 0;
  }

  p {
    margin-top: 60px;
    font-size: 48px;
  }

  @media (max-width: 1400px) {
    margin-left: 85px;

    .title-small-text {
      font-size: 18px;
    }

    p {
      margin-top: 55px;
      font-size: 39px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 992px) {
    .title-small-text {
      font-size: 15px;
    }

    p {
      margin-top: 30px;
      font-size: 32px;
    }
  }

  @media (max-width: 767px) {
    margin-left: 10px;

    .title-small-text {
      font-size: 12px;
    }

    p {
      margin-top: 18px;
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    margin-left: 10px;

    .title-small-text {
      font-size: 7px;
    }

    p {
      margin-top: 11px;
      font-size: 14px;
    }
  }
}

.spring-top-buttons {
  display: flex;
  gap: 50px;
  margin-left: 90px;
  margin-top: 85px;

  @media (max-width: 1400px) {
    margin-left: 55px;
    margin-top: 35px;
    gap: 30px;

    a {
      width: 300px;
    }

    img {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    margin-top: 233px;
  }

  @media (max-width: 767px) {
    margin-left: 5px;
    margin-top: 15px;
    max-width: 350px;
    gap: 15px;
  }
  @media (max-width: 520px) {
    margin-left: 0px;
    margin-top: 15px;
    max-width: 235px;
    gap: 0px;
  }
}

.drawing-conditions {
  display: flex;
  justify-content: center;
  margin-top: 150px;

  @media (max-width: 1400px) {
    margin-top: 65px;
  }

  @media (max-width: 520px) {
    margin-top: 30px;
  }

  a {
    margin: 0 7px;
  }
}

.drawing-conditions>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  padding: 0 15px;

  &>div:first-child {
    height: 205px;
    width: 215px;
  }


  p {
    margin-top: 95px;
    text-align: center;
    font-size: 35px;
    margin-bottom: 0;
  }

  @media (max-width: 1400px) {
    &>div:first-child {
      height: 143.5px;
      width: 150.5px;
      background-size: cover;
    }


    p {
      margin-top: 65px;
      font-size: 27px;
    }
  }

  @media (max-width: 992px) {
    &>div:first-child {
      height: 102.5px;
      width: 107.5px;
    }


    p {
      margin-top: 35px;
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    padding: 0 5px;

    &>div:first-child {
      height: 61.5px;
      width: 64.5px;
    }


    p {
      margin-top: 17px;
      font-size: 12px;
    }
  }

  &:nth-child(1)>div:first-child {
    background-image: url(./assets/condition-icons1.png);
  }

  &:nth-child(2)>div:first-child {
    background-image: url(./assets/condition-icons2.png);
  }

  &:nth-child(3)>div:first-child {
    background-image: url(./assets/condition-icons3.png);
  }
}

.gifts-block,
.moda-block,
.familly-block,
.gastro-block {
  position: relative;
  margin: 100px 50px;
  border-radius: 50px;
  padding: 50px 65px 100px;

  h2 {
    margin: 0;
    margin-bottom: 100px;
  }


  img {
    max-width: 100%;
  }

  @media (max-width: 1400px) {
    padding: 30px 50px 65px;
    margin: 60px 25px;
  }

  @media (max-width: 992px) {
    padding: 30px 25px 65px;
    margin: 50px 15px;
  }

  @media (max-width: 992px) {
    padding: 30px 25px 65px;
    margin: 50px 15px;
  }

  @media (max-width: 992px) {
    padding: 25px 10px;
    margin: 35px 10px;
  }
}

.gifts-block {
  margin-top: 150px;
  background-image: linear-gradient(-165deg, rgba(184, 113, 184, 0.5), rgba(61, 157, 165, 0.3), rgba(1, 233, 191, 0.3));

  @media (max-width: 1400px) {
    margin-top: 50px;
  }
}

.moda-block {
  background-image: linear-gradient(65deg, rgba(177, 147, 203, 0.5), rgba(96, 228, 222, 0.3));
}

.familly-block {
  background-image: linear-gradient(-94deg, rgba(118, 233, 214, 0.3), rgba(82, 205, 183, 0.5), rgba(159, 174, 230, 0.5));
}

.gastro-block {
  background-image: linear-gradient(68deg, rgba(1, 233, 191, 0.3), rgba(184, 113, 184, 0.5), rgba(74, 129, 213, 0.3), rgba(0, 124, 222, 0.3));
}

.gifts-block,
.familly-block {
  h2 {
    text-align: left;
    font-size: 120px;
  }

  @media (max-width: 1400px) {
    h2 {
      font-size: 88px;
      margin-bottom: 50px;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 42px;
      margin-bottom: 20px;
    }
  }

}

.moda-block,
.gastro-block {
  h2 {
    text-align: right;
    font-size: 120px;
  }

  @media (max-width: 1400px) {
    h2 {
      font-size: 88px;
      margin-bottom: 50px;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 43px;
      margin-bottom: 20px;
    }
  }
}

.gifts-block h2 {
  margin-bottom: 50px;

  @media (max-width: 1400px) {
    margin-bottom: 20px;
  }
}


.gift-logo {
  position: absolute;
  right: 159px;
  top: -75px;

  @media (max-width: 1400px) {
    top: -65px;
    right: 40px;
    height: 140px;
    width: 154px;
    object-fit: contain;
  }

  @media (max-width: 767px) {
    top: -8px;
    right: 9px;
    height: 70px;
    width: 77px;
  }
}

.moda-logo {
  position: absolute;
  left: 125px;
  top: 8px;

  @media (max-width: 1400px) {
    top: 9px;
    left: 70px;
    height: 126px;
    width: 128px;
    object-fit: contain;
  }

  @media (max-width: 992px) {
    left: 10px;
  }

  @media (max-width: 992px) {
    top: 10px;
    left: 20px;
    height: 63px;
    width: 64px;
  }
}

.familly-logo {
  position: absolute;
  right: 125px;
  top: 8px;

  @media (max-width: 1400px) {
    top: 7px;
    right: 90px;
    height: 115px;
    width: 108px;
    object-fit: contain;
  }

  @media (max-width: 992px) {
    right: 10px;
  }

  @media (max-width: 767px) {
    right: 14px;
    height: 57.5px;
    width: 54px;
  }
}

.gastro-logo {
  position: absolute;
  left: 64px;
  top: 55px;

  @media (max-width: 1400px) {
    left: 60px;
    top: 20px;
    height: 112px;
    width: 131px;
    object-fit: contain;
  }

  @media (max-width: 992px) {
    display: none;
  }
}

.gift-logos,
.moda-logos,
.familly-logos,
.gastro-logos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  grid-gap: 65px;
  justify-items: center;

  @media (max-width: 1400px) {
    grid-gap: 50px;
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 35px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  img {
    object-fit: cover;
  }
}


.gift-logos {
  align-items: baseline;

  @media (max-width: 767px) {
    align-items: center;
  }
}

.spring-discounts {
  text-align: center;
  font-size: 35px;
  margin-bottom: 0;

  @media (max-width: 1400px) {
    font-size: 27px;
  }

  @media (max-width: 992px) {
    font-size: 21px;
  }

  @media (max-width: 992px) {
    font-size: 15px;
    margin-top: 7px;
  }
}

.resume-block h2 {
  margin-bottom: 40px;

  @media (max-width: 1400px) {
    margin-bottom: 0;
  }
}

.resume-form {
  display: flex;
  flex-direction: column;
  width: 850px;
  margin: 0 auto;
  align-items: center;
  position: relative;

  input {
    width: 100%;
    margin-top: 30px;
    padding: 45px 35px;
    border-radius: 10px;
    border: none;
    font-size: 28px;
    font-weight: 600;
    box-shadow: 0 12px 9px #023959;
    transition: box-shadow 0.5s;
  }

  input:focus {
    outline: none;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 18px #ebdfb0;
  }

  input:not([type="checkbox"]):focus+label,
  input.filled+label {
    font-size: 20px;
    top: 32px;
  }

  input:not([type="checkbox"]):focus+label,
  .input-wrapper.has-error input.filled+label {
    transform: translateY(0);
  }

  label {
    position: absolute;
    font-size: 28px;
    color: #467a92;
    top: 50%;
    left: 35px;
    font-weight: 600;
    cursor: text;
    transition: 0.3s;
    text-shadow: none;
  }

  .file-label {
    cursor: pointer;
    width: max-content;
    position: static;
  }

  input[type="file"] {
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  button {
    width: max-content;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  .form-group {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
    position: relative;
  }

  .files {
    font-size: 25px;
  }

  .file {
    display: flex;
    align-items: center;
    font-size: 25px;

    button {
      margin-left: 20px;
      color: #a94442;
      font-weight: bold;
    }
  }

  .files-errors {
    font-size: 22px;
    color: #a94442;
    margin: 15px 0;
    text-align: center;

    @media (max-width: 767px) {}
  }

  .checkbox {
    height: 1px;
    opacity: 0;
    position: absolute;
    width: 1px;
    z-index: -1;
    left: 30px;
    top: 50%;
  }

  .checkbox+label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: 100;
    user-select: none;
    position: static;
    color: #fff;
    font-size: 30px;

    a {
      margin-left: 10px;
    }

    @media (max-width: 1400px) {
      font-size: 20px;
    }

    @media (max-width: 767px) {
      font-size: 12px;

      a {
        margin-left: 3px;
      }
    }
  }

  .checkbox+label:before {
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 1px;
    background-size: 50% 50%;
    background-color: #00436d;
    content: "";
    display: inline-block;
    height: 50px;
    margin-right: 35px;
    width: 50px;

    @media (max-width: 1400px) {
      height: 35px;
      width: 35px;
      margin-right: 21px;
    }

    @media (max-width: 767px) {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }

  .checkbox:checked+label:before {
    background-image: url(./assets/checkbox.svg);
  }


  @media (max-width: 1400px) {
    width: 600px;

    .form-group button,
    .file-label {
      width: 300px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    input {
      padding: 30px 20px;
      font-size: 23px;
      margin-top: 18px;
    }

    label {
      left: 20px;
      font-size: 23px;
    }

    input:not([type=checkbox]):focus+label,
    input.filled+label {
      font-size: 16px;
      top: 22px;
    }

    .files {
      font-size: 22px;
    }

    .file {
      font-size: 20px;
      margin: 7px 0;
    }
  }

  @media (max-width: 767px) {
    width: 85%;

    .form-group {
      gap: 5px;
    }

    input {
      padding: 22px 14px;
      font-size: 16px;
      margin-top: 14px;
    }

    label {
      left: 14px;
      font-size: 16px;
    }

    input:not([type=checkbox]):focus+label,
    input.filled+label {
      font-size: 12px;
    }

    .files {
      font-size: 16px;
    }

    .file {
      text-align: center;
      font-size: 14px;

      button {
        margin-left: 10px;
      }
    }

    .files-errors {
      font-size: 15px;
      margin: 3px 0;
    }
  }
}

.input-wrapper {
  position: relative;
  width: 100%;
  height: max-content;

  .help-block {
    font-size: 20px;
    bottom: -22px;
    left: 5px;
    color: #da1c19;
    display: none;
    position: absolute;
  }

  &.has-error .help-block {
    display: block;
  }

  @media (max-width: 1400px) {
    .help-block {
      font-size: 15px;
      bottom: -16px;
    }
  }

  @media (max-width: 767px) {
    .help-block {
      font-size: 12px;
      bottom: -12px;
    }
  }
}

.QABlock {
  h2 {
    margin-bottom: 10px;
  }

  .inform {
    text-align: center;
    font-size: 35px;
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 0 75px;
  }

  @media (max-width: 1400px) {
    .inform {
      font-size: 22px;
      margin-top: 12px;
      margin-bottom: 35px;
      padding: 0 75px;
    }
  }

  @media (max-width: 767px) {
    .inform {
      font-size: 14px;
      padding: 0 10px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
}

.QA-wrapper {
  line-height: 250%;
  width: 1100px;
  margin: 35px auto 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0;
  padding-bottom: 150px;

  @media (max-width: 1400px) {
    width: 800px;
    line-height: 150%;
  }

  @media (max-width: 992px) {
    width: 90%;
    margin: 15px auto 0;
    padding-bottom: 50px;
  }

  @media (max-width: 767px) {
    line-height: 115%;
  }
}

.QAItem {
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  padding: 35px;
  list-style: none;
  font-size: 25px;
  text-shadow: none;

  p {
    margin: 0;
  }

  strong {
    margin-right: 10px;
  }

  h3 {
    margin: 0;
    display: flex;
    font-size: 35px;
    justify-content: space-between;
    color: #467a92;
    cursor: pointer;
    align-items: center;
    gap: 10px;

    i {
      font-style: normal;
      transform: rotate(45deg);
      border: none;
      background-color: transparent;
      color: #467a92;
      transition: 0.2s;

      @media (max-width: 767px) {
        font-family: AeroportLight;
      }
    }
  }

  .QAContent {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;

    ul {
      list-style-type: disc;
      margin-bottom: 15px;

      @media (max-width: 767px) {
        padding-left: 25px;
      }
    }
  }

  @media (max-width: 1400px) {
    padding: 25px;
    font-size: 18px;

    h3 {
      font-size: 25px;
    }
  }

  @media (max-width: 767px) {
    padding: 10px;
    font-size: 14px;

    h3 {
      font-size: 18px;
    }
  }
}

.QAItem.open {
  h3 {
    color: #000;
    margin-bottom: 25px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    i {
      transform: rotate(0);
    }
  }
}

.spring-slider {
  width: 700px;
  background-size: 100%;

  @media (max-width: 1400px) {
    width: 500px;
  }

  @media (max-width: 992px) {
    width: 350px;
  }

  @media (max-width: 767px) {
    width: 220px;
  }
}

.spring-slider-item {
  display: flex;
  flex-direction: column;
  max-width: max-content;

  div img {
    object-fit: contain;
    max-width: 70%;
    max-height: 110px;
    margin: 0 auto;
    margin-bottom: 25px;

    @media (max-width: 992px) {
      max-height: 70px;
    }

    @media (max-width: 520px) {
      max-height: 45px;
    }
  }

  p {
    text-transform: uppercase;
    font-size: 40px;
    text-align: center;
    margin: 0;

    @media (max-width: 1400px) {
      font-size: 28px;
    }

    @media (max-width: 992px) {
      font-size: 23px;
    }

    @media (max-width: 992px) {
      font-size: 18px;
    }
  }


}

.spring-slider-wrapper {
  position: relative;
  width: max-content;
  margin: 0 auto;

  .navigationNext,
  .navigationPrev {
    border: none;
    background-color: transparent;
    background-size: cover;
    width: 229px;
    height: 263px;
    padding: 0;
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    background-image: url(./assets/arrow.png);
    cursor: pointer;

    @media (max-width: 1400px) {
      width: 160px;
      height: 184px;
    }

    @media (max-width: 992px) {
      width: 114.5px;
      height: 131.5px;
    }

    @media (max-width: 992px) {
      width: 69px;
      height: 79px;
    }
  }

  .navigationPrev {
    scale: -1;
    left: -35%;

  }

  .navigationNext {
    right: -35%;
  }
}

.spring-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-content: center;
  font-family: AeroportLight;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0s 0.5s;
}

.spring-popup.active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.popup-content {
  display: flex;
  flex-direction: column;
  padding: 80px 150px;
  background-color: #fff;
  align-items: center;
  max-width: 840px;
  text-align: center;
  position: relative;
  border-radius: 5px;

  h3 {
    font-family: AeroportMedium;
    color: #3E7898;
    font-size: 32px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0;
    font-size: 22px;
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    border-radius: 8px;
    background: #A2DDFF;
    padding: 30px 100px;
    margin-top: 60px;
    font-size: 22px;
  }

  button {
    border: none;
    cursor: pointer;
    background: transparent;
    font-size: 25px;
    right: 23px;
    top: 26px;
    position: absolute;
  }

  @media (max-width: 992px) {
    max-width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    padding: 25px;

    a {
      padding: 20px 50px;
    }

    p {
      font-size: 16px;
    }

    h3 {
      margin-top: 10px;
      font-size: 25px;
    }

    button {
      right: 10px;
      top: 10px;
    }
  }
}

.clever-slider-item {
  width: 70% !important;
  margin: auto auto 120px;
  padding-top: 130px;

  @media (max-width: 1400px) {
    padding-top: 80px;
    margin: auto auto 110px;
  }

  @media (max-width: 992px) {
    margin: auto auto 50px;
  }

  @media (max-width: 767px) {
    padding-top: 45px;
    margin: auto auto 20px;
  }
}