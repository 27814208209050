.preloader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  &__background {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    z-index: 4;
    left: 0;
    top: 0;
  }
  &__circle {
    position: absolute;
    border: 2px solid #C1C1C1;
    border-top: 6px solid #244486;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  &__logo {
    position: absolute;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
