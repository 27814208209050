.register {
  max-width: 57.29%;
  margin: 50px auto 130px;
  display: flex;
  flex-direction: column;

  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    margin-top: 0;
    margin-bottom: 50px;
  }
  &__subtitle {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 110%;
    margin-top: 0;
    margin-bottom: 50px;
  }
  &__description {
    margin: 0 0 40px 0;
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__label {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    white-space: nowrap;
    &:focus-within {
      border-bottom: 1px solid rgba(0, 0, 0, 1);
    }
  }
  &__label-checkbox {
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  &__input {
    margin-left: 10px;
    border: none;
    &::placeholder {
      font-family: Raleway, serif;
      font-size: 16px;
      line-height: 19px;
    }
    &:focus {
      outline: none;
    }
  }
  &__input-checkbox {
    margin-right: 13px;
  }
  &__checkboxes-title {
    @extend .register__description;
    margin-bottom: 20px;
  }
  &__checkboxes-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  &__buttons-container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  &__submit {
    padding: 20px 50px;
    background-color: #000000;
    border: 1px solid rgba(0, 0, 0, 1);
    color: #ffffff;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
  &__instruction {
    display: flex;
    text-decoration: none;
    justify-content: center;
    padding: 20px 50px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 1);
    color: #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
  &__error {
    color: #ec4040;
    font-family: Raleway, serif;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 1200px) {
  .register {
    max-width: 83.33%;
    margin: 50px auto 100px;
    &__title {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 40px;
    }
    &__subtitle {
      font-size: 18px;
    }
    &__description {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 30px;
    }
    &__label {
      font-size: 18px;
      line-height: 22px;
      display: flex;
      flex-direction: row;
    }
    &__input {
      margin-left: 10px;
      width: 100%;
    }
    &__label-checkbox {
      margin: 0;
      font-size: 14px;
      line-height: 16px;
    }
    &__checkboxes-title {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 0;
    }
    &__checkboxes-container {
      flex-wrap: wrap;
      column-gap: 40px;
      row-gap: 20px;
    }
    &__label-checkbox {
      margin-top: 20px;
    }
    &__buttons-container {
      flex-direction: column;
    }
    &__submit {
    }
    &__instruction {
      padding: 20px 0;
      text-align: center;
    }
  }
}