.qrpage {
  height: 100vh;
  background-image: url("../../vendor/img/qr-bg.png");
  background-size: contain;
  font-family: 'Raleway', serif;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    height: 90%;
    margin: 0 auto;
  }
  &__chat-container {
    margin: 50px 0 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #0536B5;
    border-radius: 8px;
    width: 100%;
    padding: 12px;
    gap: 20px;
  }
  &__text {
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    margin: 0;
  }
  &__buttons-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    justify-content: center;
  }
  &__chat-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0536B5;
    border-radius: 4px;
    width: 200px;
    padding: 8px;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;
    width: 100%;
    color: #ffffff;
    background-color: #0536B5;
    border-radius: 4px;
    text-decoration: none;
    height: 52px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}

@media screen and (max-width: 1000px) {
  .qrpage {
    &__container {
      max-width: 80%;
    }
  }
}

@media screen and (max-width: 550px) {
  .qrpage {
    &__container {
      max-width: 94%;
    }
    &__buttons-container {
      gap: 4px;
    }
    &__chat-button {
      width: 50%;
    }
  }
}

@media screen and (max-width: 420px) {
  .qrpage {
    &__logo {
      width: 224px;
      height: auto;
    }
    &__text {
      font-size: 20px;
    }
    &__button {
      font-size: 14px;
      line-height: 17px;
      height: 40px;
      margin-bottom: 24px;
    }
    &__chat-container {
      margin: 36px 0 24px
    }
  }
}
