.pages-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 50px;
}

.page {
  font-family: Raleway, serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    color: #ffffff;
    background-color: #000000;
  }
}

.page-active {
  background-color: #000000;
  color: #ffffff;
}