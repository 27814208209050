.choosenEvent {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  &__mainSwiper {
    width: 620px;
    height: 620px;
    margin-bottom: 20px;
  }
  &__miniSwiper {
    width: 620px;
    height: 200px;
  }
  &__mainSwiperSlide {
    max-width: 620px;
  }
  &__block {
    display: flex;
    flex-direction: row;
    gap: 96px;
    margin-top: 50px;
    margin-bottom: 130px;

  }

  &__image-container {
    width: 620px;
    height: 620px;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__image-block {
    display: flex;
    flex-direction: column;

  }

  &__text-block {
    display: flex;
    flex-direction: column;
  }

  &__share-buttons-block {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }

  &__share-button {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
    transition: all 0.5s ease;
    &:before {
      content: url("../../vendor/img/share.svg");
      margin-right: 25px;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  &__social-logo {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    margin-bottom: 40px;
  }

  &__time {
    font-family: "Cormorant Garamond", serif;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 60px;
  }

  &__description {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }

  &__other-promos-block {
    max-width: 1740px;
    margin: 0 auto;
  }

  &__other-promos-text {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    text-align: left;
  }
  &__button-buy {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 220px;
      height: 60px;
      border: 1px solid #000000;
      font-family: Raleway, serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      background-color: #ffffff;
      color: #000000;
      transition: all 0.5s;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        background-color: black;
        color: #ffffff;
      }
  }
}

.choosenEvent__miniSwiper .swiper-slide {
  opacity: 0.4;
}

.choosenEvent__miniSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

@media screen and (max-width: 1500px) {
  .choosenEvent {
    &__block {
      gap: 20px;
    }
    &__mainSwiper {
      width: 380px;
      height: 380px;
    }
    &__miniSwiper {
      width: 380px;
      height: 170px;
    }
    &__mainSwiperSlide {
      max-width: 380px;
    }
    &__image-container {
      width: 380px;
      height: 380px;
    }
    &__title {
      font-size: 48px;
      line-height: 58px;
    }
    &__time {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 40px;
    }
    &__description {
      font-size: 14px;
      line-height: 150%;
    }
    &__other-promos-text {
      font-size: 48px;
      line-height: 58px;
    }
  }
}

@media screen and (max-width: 800px) {
  .choosenEvent {
    &__block {
      flex-direction: column;
      margin-bottom: 50px;
    }
    &__mainSwiper {
      width: 300px;
      height: 300px;
    }
    &__miniSwiper {
      width: 300px;
      height: 150px;
    }
    &__mainSwiperSlide {
      max-width: 300px;
    }
    &__image-container {
      width: 300px;
      height: 300px;
    }
    &__image-block {
      margin-left: auto;
      margin-right: auto;
    }
    &__title {
      font-size: 30px;
      line-height: 36px;
    }
    &__time {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 45px;
    }
  }
}
