.shopsBlock {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  &__categories-block {
    display: flex;
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  &__filter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 154px;
    height: 60px;
    text-align: center;
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    color: #000000;

    &_active {
      @extend .shopsBlock__filter;
      background-image: url("./../../vendor/img/background-active-link.svg");
      border: 1px solid #000000;
    }
  }

  &__navigation-block {
    border: 1px solid #000000;
    width: 462px;
    height: fit-content;
  }

  &__iframe-container {
    background: #F1EFF0;
    width: 100%;
    height: 850px;
    margin-top: 50px;
    margin-bottom: 130px;

    & iframe {
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      width: 100%;
      display: block;
      border-style: none;
    }
  }
}

@media screen and (max-width: 1615px) {
  .shopsBlock {
    &__navigation-block {
      width: 300px;
      margin-left: 90px;
    }
  }
}

@media screen and (max-width: 850px) {
  .shopsBlock {
    &__content {
      flex-direction: column;
    }

    &__navigation-block {
      margin-left: auto;
      margin-right: auto;
    }
    &__iframe-container {
      height: 500px;
    }
  }
}
