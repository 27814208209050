.changeInfo {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    &:focus-within {
      border-bottom: 1px solid rgba(0, 0, 0, 1);
    }
  }

  &__key {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    margin: 0;
    white-space: nowrap;
  }
  &__input {
    font-family: Raleway, serif;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    border: none;
    &:focus {
      outline: none;
    }
  }
  &__input-div {
    font-family: Raleway, serif;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    border: none;
    cursor: pointer;
  }
  &__checkboxes-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;
  }
  &__popup {
    position: absolute;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    z-index: 1;
    top: 30px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 1);
    & ul {
      list-style: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding:18px 20px 13px;
      & li {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        &.active {
          &::after {
            content: url("../../../vendor/img/radio-button.svg");
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  &__checkbox-label {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  &__checkbox {
    margin-right: 13px;
  }
  &__buttons-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  &__submit {
    padding: 20px 50px;
    background-color: #000000;
    border: 1px solid rgba(0, 0, 0, 1);
    color: #ffffff;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
  &__reset {
    padding: 20px 50px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 1);
    color: #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
}

.hidden {
  display: none;
}

@media screen and (max-width: 1200px) {
  .changeInfo {
    max-width: 83.33%;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
    &__key {
      font-size: 18px;
      line-height: 22px;
    }
    &__input {
      font-size: 14px;
      line-height: 16px;
    }
    &__buttons-container {
      flex-direction: column;
    }
    &__submit {
      max-width: 300px;
    }
    &__reset {
      max-width: 300px;
    }
  }
}