.loginPopup {
  &__layout {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    visibility: hidden;
    opacity: 0;
    &_opened {
      visibility: visible;
      opacity: 1;
    }
  }
  &__content {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 460px;
    height: 730px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    text-align: center;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  &__white-button {
    margin-top: 20px;
    background-color: #ffffff;
    width: 300px;
    height: 61px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    cursor: pointer;
    transition: color 0.2s linear, background-color 0.2s linear;
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
  &__text {
    margin-top: 20px;
    margin-bottom: 0;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  &__phone {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }
  &__icons-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  &__link {
    height: 24px;
    &:hover {
      opacity: 0.5;
    }
  }
  &__phone-smaller {
    margin-top: 10px;
    margin-bottom: 0;
    font-family: Raleway, serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
  }
  &__text-smaller {
    margin: 0;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
}

@media screen and (max-width: 600px) {
  .loginPopup {
    &__content {
      width: 340px;
      height: 670px;
    }
    &__text {
      font-size: 14px;
      line-height: 21px;
    }
    &__phone {
      font-size: 14px;
      line-height: 21px;
    }
    &__icons-container {
      gap: 24px;
    }
  }
}
