.getPasswordPopup {
  &__content {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 460px;
    height: 760px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    text-align: center;
  }
}