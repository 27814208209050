.singleShop {
  position: relative;
  z-index: 1;
  max-width: 300px;

  &__image-container {
    width: 300px;
    height: 300px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__collection {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  &__tags-block {
    position: absolute;
    left: 10px;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  &__tag_black {
    padding: 4px 8px;
    color: #000;
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 500;
  }
  &__tag_white {
    border: 1px solid #000;
    background: #ffffff;
    color: #000000;
    padding: 6px 8px;
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 500;
  }
  &__link {
    color: #000000;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
  &__image {
    width: 180px;
    height: 180px;
    object-position: center;
  }
  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 39px;
    color: #000000;
    font-style: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__tags {
    font-family: Raleway, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #00000040;
  }
  &__map-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Raleway, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }
}
