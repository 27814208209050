.footerMobile {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  background-color: #F0F0F0;
  padding-top: 58px;
  &_shop {
    position: relative;
    z-index: 3;
  }
  &__block {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  &__link {
    color: #000000;
    text-decoration: none;
    transition: all 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
  &__title {
    font-family: "Raleway", serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
  }
  &__images-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 47px;
    gap: 30px;
  }
  &__accordions-container {
    display: flex;
    flex-direction: column;
    gap: 45px;
  }
  &__accordion-block {

  }
  &__accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Raleway', serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    align-items: center;
    cursor: pointer;
  }
  &__accordion-content {
    margin-top: 27px;
    font-family: 'Raleway', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    color: #000000;
  }
}
