.promotions {
  max-width: 90%;
  margin: 0 auto;
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__link {
    margin-right: 5%;
    font-family: 'Cormorant Garamond', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    &:hover {
      opacity: 0.5;
    }
  }
  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    text-align: left;
  }
}

@media screen and (max-width: 1500px) {
  .promotions {
    &__title {
      font-size: 48px;
      line-height: 58px;
    }
    &__link {
      font-size: 30px;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: 450px) {
  .promotions {
    &__link {
      display: none;
    }
  }
}


