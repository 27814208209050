.genericPopup {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  &_visible {
    visibility: visible;
    opacity: 1;
  }
  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px 20px 50px;
    background-color: #ffffff;
    text-align: center;
    max-width: 460px;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    margin: 0 0 10px 0;
  }
  &__description {
    font-family: Raleway, serif;
    font-weight: 400;
    margin: 0 0 30px;
    font-size: 16px;
    line-height: 19px;
  }
  &__button {
    padding: 20px 93px;
    font-family: Raleway, serif;
    color: #000000;
    margin-top: 30px;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    height: 61px;
    border: 1px solid rgba(0, 0, 0, 1);
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
}