.cancelSmsPopup {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  &_visible {
    visibility: visible;
    opacity: 1;
  }
  &__content {
    max-width: 660px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    padding: 50px 80px;
    background-color: #ffffff;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    margin: 0 0 20px 0;
  }
  &__checkboxes-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  &__textarea {
    padding: 10px;
    margin-top: 42px;
    margin-bottom: 20px;
    height: 100px;
    max-width: 500px;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    &::placeholder {
      color: #CCCCCC;
    }
  }
  &__button {
    padding: 20px 93px;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    height: 61px;
    border: 1px solid rgba(0, 0, 0, 1);
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
}