.notfound {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
  font-family: "Cormorant Garamond", serif;
  &__title {
    font-size: 64px;
  }
  &__link {
    font-family: Raleway, serif;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 60px;
    font-size: 18px;
    color: #ffffff;
    border: 1px solid #000000;
    background-color: #000000;
    text-decoration: none;
    transition: all 0.5s ease;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
}

@media screen and (max-width: 600px) {
  .notfound {
    &__title {
      font-size: 46px;
    }
  }
}