.tgPopup {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 580px;
    background-color: #0057b8;
    background-image: url("../../../src/vendor/img/tg.gif");
    background-position: top center;
    background-repeat: no-repeat;
    color: #ffffff;
    text-align: center;
  }
  &__line {
    margin-top: 16px;
    width: 32px;
    background-color: #ffffff;
    height: 4px;
    border-radius: 2px;
    border: none;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  &__title {

    margin: 30px auto 40px;
    font-size: 35px;
    font-weight: 400;
  }
  &__black-button {
    background-color: #000000;
    width: 100%;
    height: 61px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 21px;
    color: #ffffff;
    cursor: pointer;
    transition: color 0.2s linear, background-color 0.2s linear;
    margin: 0 auto;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
    &:hover svg path {
      fill: black
    }
  }
  &__text {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    width: 80%;
    margin: auto auto 20px;
  }
  &__tg {
    width: 24px;
    height: 24px;
    display: block;
    margin-left: 20px;
  }
}

[data-rsbs-header] {
  height: 50px;
}

[data-rsbs-header]:before {
  background-color: #fff;
  top: 20px;
}

[data-rsbs-overlay] {
  background: #0057b8;
  z-index: 15;
}