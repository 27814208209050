.choosenShop {
  width: 100%;
  &__container {
    position: relative;
    background-color: #ffffff;
    padding: 0 5%;
    z-index: 5;
  }

  &__header {
    width: 100%;
    height: 632px;
    padding-top: 112px;
  }

  &__video-block {
    height: 85vh;
    width: 100%;
  }

  &__video {
    position: fixed;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    z-index: -2;
    left: 0;
    top: 0;
    &_visible {
      z-index: 2;
    }
  }

  &__collection {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  &__tags-block {
    position: absolute;
    left: 10px;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  &__tag_black {
    background: #000;
    padding: 4px 8px;
    color: #ffffff;
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 500;
  }
  &__tag_white {
    border: 1px solid #000;
    background: #ffffff;
    color: #000000;
    padding: 6px 8px;
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 500;
  }

  &__header-content {
    position: fixed;
    left: 0;
    top: 112px;
    z-index: 0;
    width: 100%;
    height: 520px;
    object-fit: cover;
    object-position: top;
  }

  &__shop-info-block {
    display: flex;
    flex-direction: row;
    gap: 90px;
    margin-bottom: 80px;
  }

  &__logo-container {
    height: 300px;
    width: 300px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &_moved {
      margin-top: -50px;
    }
  }

  &__share-buttons-block {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin: 12px 0;
    align-items: center;
  }

  &__mobile-line {
    display: none;
    width: 100px;
    height: 5px;
    border-radius: 5px;
    border: none;
    background-color: #fff;
    position: absolute;
    top: -40px;
  }

  &__logo {
    width: 220px;
    height: 220px;
    display: block;
  }

  &__shop-info {
    display: flex;
    flex-direction: column;
    width: 1000px;
    padding-top: 30px;
    line-height: normal;
  }

  &__title {
    font-family: "Cormorant Garamond", serif;
    font-size: 48px;
    font-weight: 500;
    margin: 0;
  }

  &__floor {
    font-family: "Cormorant Garamond", serif;
    font-size: 30px;
    font-weight: 300;
    margin: 0 0 48px;

  }

  &__description {
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  &__blocks-container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    background-color: #fff;
    position: relative;
  }

  &__gallery {
    margin: 60px 0;
  }
  &__promotion {
    margin: 100px auto 80px;
    max-width: 1100px;
  }
  &__promotion-title {
    font-family: "Cormorant Garamond", serif;
    font-size: 48px;
    font-weight: 500;
    margin: 0;
  }
  &__promotion-container {
    display: flex;
    flex-direction: row;
    gap: 100px;
    margin-top: 40px;
  }
  &__promotion-image {
    width: 380px;
    height: 380px;
  }
  &__promotion-info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__promotion-info-title {
    margin: 0;
    font-family: "Cormorant Garamond", serif;
    font-size: 30px;
    font-weight: 300;
  }
  &__promotion-date {
    margin: 5px 0 0;
    font-size: 18px;
    font-weight: 700;
    font-family: "Cormorant Garamond", serif;
  }
  &__promotion-description {
    font-family: Raleway, serif;
    margin: 25px 0 0;
    font-size: 14px;
    font-weight: 400;
  }
  &__map {
    margin: 80px 0 0;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 100px;
    justify-content: center;
  }
  &__map-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__map-floor {
    font-family: Raleway, serif;
    font-size: 64px;
    font-weight: 500;
    margin: 0;
    line-height: 100%;
    &_number {
      font-size: 100px;
    }
  }
  &__map-time {
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 400;
    margin: 20px 0 0;
  }
  &__map-iframe-container {
    width: 620px;
    height: 620px;
  }
  &__map-iframe {
    width: 100%;
    height: 100%;
  }
}

.infoblock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
  max-width: 1100px;
  margin: 0 auto;

  &_reversed {
    flex-direction: row-reverse;
  }

  &__title {
    font-family: "Cormorant Garamond", serif;
    margin: 0;
    font-size: 30px;
    font-weight: 300;
  }

  &__description {
    font-family: Raleway, serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
}

.infoblockImages {
  margin: 160px auto 120px;

  &__container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
  }
  &__main-image-container {
    width: 700px;
    height: 780px;
  }
  &__images-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__secondary-image-container {
    width: 380px;
    height: 380px;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiperInfoblock {
  margin: 0;
  position: relative;

  &__swiper {
    height: 408px;
    width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__slide {
    height: 380px;
    margin: 0 auto;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__navigation {
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  &__prev {
    left: -20px;
    top: 170px;
    background-image: url("../../vendor/img/arrow-slider-left.svg");
  }

  &__next {
    left: 440px;
    top: 170px;
    background-image: url("../../vendor/img/arrow-slider-right.svg");
  }

  &__pagination-container {
    width: 100%;
    height: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__pagination-bullet {
    flex-grow: 1;
    height: 2px;
    display: block;
    background-color: #D9D9D9;
  }

  &__pagination-bullet-active {
    height: 100%;
    background-color: #000000;
  }
}

.swiperGallery {
  width: 100%;
  position: relative;

  &__swiper {
    height: 608px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__slide {
    height: 580px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__navigation {
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    top: 270px;
  }

  &__prev {
    left: -20px;
    background-image: url("../../vendor/img/arrow-slider-left.svg");
  }

  &__next {
    right: -20px;
    background-image: url("../../vendor/img/arrow-slider-right.svg");
  }

  &__pagination-container {
    width: 100%;
    height: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__pagination-bullet {
    flex-grow: 1;
    height: 2px;
    display: block;
    background-color: #D9D9D9;
  }

  &__pagination-bullet-active {
    height: 100%;
    background-color: #000000;
  }
}

@media screen and (max-width: 1700px) {
  .choosenShop {
    &__shop-info {
      width: 620px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .choosenShop {
    &__header {
      padding-top: 86px;
    }
    &__header-content {
      top: 86px;
    }
    &__shop-info-block {
      flex-direction: column;
      gap: 30px;
    }
    &__mobile-line {
      display: block;
    }
    &__logo-container {
      margin-left: auto;
      margin-right: auto;
    }
    &__shop-info {
      width: 100%;
    }
    &__floor {
      margin: 5px 0 32px;
    }
    &__promotion-container {
      flex-direction: column;
      gap: 24px;
    }
    &__map {
      flex-direction: column;
      gap: 20px;
    }
    &__map-time {
      font-size: 20px;
      margin-top: 10px;
    }
    &__map-iframe-container {
      width: 360px;
      height: 360px;
    }
  }

  .infoblock {
    flex-direction: column;
    gap: 64px;
  }

  .infoblockImages {
    margin: 80px auto 80px;
    &__container {
      flex-direction: column;
      align-items: center;
    }
    &__images-container {
      flex-direction: row;
    }
    &__main-image-container {
      width: 300px;
      height: 440px;
    }
    &__secondary-image-container {
      width: 140px;
      height: 250px;
    }
  }

  .swiperInfoblock {
    &__swiper {
      width: 360px;
    }
    &__slide {
      max-width: inherit;
    }
    &__prev {
      left: 10px;
    }
    &__next {
      right: 10px;
      left: auto;
    }
  }

  .swiperGallery {
    &__navigation {
      display: none;
    }
  }
}

@media screen and (max-width: 420px) {
  .choosenShop {
    &__blocks-container {
      width: 100%;
    }



    &__promotion-image {
      width: 100%;
      height: auto;
    }
  }

  .infoblock {
    max-width: 100%;
  }

  .swiperInfoblock {
    width: 100%;
    &__swiper {
      width: 100%;
    }
  }
}
