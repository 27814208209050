.privacy {
  max-width: 80%;
  margin: 0 auto 50px auto;
  font-family: "Cormorant Garamond", serif;
  &__section {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  &__sectionTitle {
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    text-align: center;
    color: #03286B;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    box-sizing: border-box;
    padding: 10px 0;
  }
  &__text {
    font-size: 20px;
    margin: 0;
    box-sizing: border-box;
    padding: 10px 0;
  }
  &__highlighted {
    font-weight: 700;
  }
  &__list {
    margin: 0;
  }
}