.burgerMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  &_shop {
    position: fixed;
    top: 112px;
    z-index: 2;
    width: 100%;
  }

  &__link {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 1700px) {
  .burgerMenu {
    gap: 40px;

    &__link {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .burgerMenu {
    gap: 30px;
  }
}

@media screen and (max-width: 1350px) {
  .burgerMenu {
    gap: 20px;
  }
}



