.burgerMenuMobile {
  position: fixed;
  z-index: 7;
  width: 100%;
  height: 100%;
  border-top: 1px solid #f0f0f0;
  background-color: #ffffff;
  top: 0;
  visibility: hidden;
  &_active {
    visibility: visible;
  }
  &__content {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #ffffff;
  }
  &__icons-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    margin-top: 21px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  &__button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  &__logo {
    width: 24px;
    height: 24px;
  }
  &__logo-text {
    font-style: normal;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  &__links-container {
    height: 100%;
    list-style: none;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0 186px 0;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  &__link {
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #000000;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
}
