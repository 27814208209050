.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  &__label {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: start;
    margin-bottom: 10px;
  }

  &__input {
    border: 1px solid rgba(0, 0, 0, 1);
    width: 300px;
    height: 60px;
    padding: 20px;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  &__button {
    background-color: #000000;
    width: 300px;
    height: 61px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    cursor: pointer;
    transition: color 0.2s linear, background-color 0.2s linear;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
  &__input-container {
    margin-bottom: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__error {
    margin-top: 10px;
    color: #ec4040;
    font-family: Raleway, serif;
    font-size: 12px;
    line-height: 14px;
  }
  &__helper {
    margin: 0 0 10px 0;
    font-family: Raleway, serif;
    font-size: 12px;
    line-height: 14px;
    color: #a0a2a0;
  }
}