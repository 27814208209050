.mainSection {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  &__grid {
    display: grid;
    width: 30%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: Raleway, serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    background-image: url("../../vendor/img/background1.svg"), url("../../vendor/img/background2.svg"), url("../../vendor/img/background3.svg"), url("../../vendor/img/background4.svg"), url("../../vendor/img/background5.svg"), url("../../vendor/img/background6.svg");
    background-repeat: no-repeat;
    background-position: -300% 300%, -300% -300%, 50% -300%, 300% -300%, 300% 300%, 50% 300%;
    transition: background-position ease 0.5s;
    &:hover {
      background-position: -40% 100%, 0 -60%, 0 -20%, 120% -20%, 100% 150%, 50% 150%;
    }
  }
  &__number {
    font-family: Raleway, serif;
    font-size: 48px;
    line-height: 56px;
    margin: 0;
  }
  &__shops {
    background-color: #03286B;
  }
  &__restaurants {
    background-color: #124D88;
  }
  &__entertainments {
    background-color: #1C659C;
    font-size: 24px;
    line-height: 28px;
  }
  &__uslugi {
    background-color: #297EB1;
    font-size: 24px;
    line-height: 28px;
  }
  &__promotions {
    background-color: #76A8D5;
    font-size: 24px;
    line-height: 28px;
  }
  &__kids {
    background-color: #B8D3F0;
    font-size: 24px;
    line-height: 28px;
  }
}



@media screen and (max-width: 1200px) {
  .mainSection {
    flex-direction: column;
    &__grid {
      width: 100%;
      grid-template-rows: 1fr 1fr 1fr;
    }
    &__item {
      width: 100%;
      height: 240px;
      background-image: none;
    }
  }
}

@media screen and (max-width: 670px) {
  .mainSection {
    &__grid {
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
    &__item {
      width: 100%;
      height: 150px;
    }
  }
}
