.deleteLoyalty {
  max-width: 80%;
  margin: 0 auto 50px auto;
  font-family: "Cormorant Garamond", serif;
  &__section {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  &__sectionTitle {
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    text-align: center;
    color: #03286B;
  }
  &__text {
    text-align: center;
    font-size: 24px;
  }
  &__link {
    color: #000;
    transition: 0.2s;
  }
  &__link:hover {
    opacity: 0.7;
  }
}