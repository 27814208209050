.shopsCategoriesFilter {
  padding: 40px 80px 50px;
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #00000040;
  list-style-type: none;
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    text-decoration: none;
    color: rgb(0 0 0 / 55%);
    &_active {
      color: #000000;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(7, 15px);
    column-gap: 34px;
    row-gap: 15px;
    margin-top: 42px;
    list-style: none;
  }
  p {
    margin: 0;
  }
}

@media screen and (max-width: 1615px) {
  .shopsCategoriesFilter {
    padding: 40px 20px 53px;
    &__grid {
      column-gap: 25px;
    }
  }
}


