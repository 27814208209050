.events {
  background-image: url("../../vendor/img/background-events.png");
  background-repeat: no-repeat;
  background-position: top 110px left 0;
  background-size: 120% 490px;
  max-width: 100%;
  margin: 112px auto 0;
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 64px;
    text-align: left;
  }
  &__link {
    margin-right: 5%;
    font-family: 'Cormorant Garamond', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    &:hover {
      opacity: 0.5;
    }
  }
}

.swiperEvents {
  margin-left: 5% !important;
}

@media screen and (max-width: 1500px) {
  .events {
    background-size: 100% 350px;
    &__title {
      font-size: 48px;
      line-height: 58px;
    }
    &__link {
      font-size: 30px;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: 700px) {
  .events {
    background-size: 100% 210px;
  }
}

@media screen and (max-width: 450px) {
  .events {
    &__link {
      display: none;
    }
  }
}



