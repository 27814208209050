.photoGalleriesPage {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  &__yearsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  &__year {
    width: 60px;
    height: 41px;
    border: 1px solid #000000;
    background-color: #ffffff;
    color: #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s linear, color 0.5s linear;
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
  &__year-active {
    background-color: #000000;
    color: #ffffff;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 70px;
    margin-bottom: 50px;
  }
  &__item {
    max-width: 860px;
  }
  &__images-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow: hidden;
  }
  &__main-image {
    width: 620px;
    height: 465px;
  }
  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__other-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 465px;
    overflow: hidden;
  }
  &__other-image {
    width: 220px;
    height: 166px;
  }
  &__description-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 10px;
  }
  &__title {
    margin: 0;
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }
  &__date {
    margin: 0;
    font-family: "Cormorant Garamond", serif;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 60px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: #ffffff;
    color: #000000;
    transition: all 0.5s;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: black;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1900px) {
  .photoGalleriesPage {
    &__grid {
      grid-row-gap: 50px;
    }
    &__item {
      max-width: 540px;
    }
    &__main-image {
      width: 380px;
      height: 286px;
    }
    &__other-images {
      max-height: 286px;
    }
    &__other-image {
      width: 144px;
      height: 107px;
    }
    &__title {
      font-size: 18px;
      line-height: 22px;
    }
    &__date {
      font-size: 30px;
      line-height: 36px;
    }
    &__link {
      height: 53px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .photoGalleriesPage {
    &__grid {
      grid-template-columns: 1fr;
      justify-items: center;
      grid-row-gap: 60px;
    }
    &__item {
      max-width: 300px;
    }
    &__main-image {
      width: 300px;
      height: 225px;
    }
    &__other-images {
      display: none;
    }
    &__description-container {
      flex-direction: column-reverse;
    }
    &__link {
      margin: 20px auto 0;
    }
  }
}