.swiperEvents {
  font-family: Raleway, serif;
  &__slide {
    width: 540px;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &__img-container {
    width: 540px;
    height: 540px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    margin-top: 23px;
    margin-bottom: 12px;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 30px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 60px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: #ffffff;
    color: #000000;
    transition: all 0.5s;
    text-decoration: none;
    margin-top: auto;

    &:hover {
      cursor: pointer;
      background-color: black;
      color: #ffffff;
    }

  }
  &__navigation {
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    top: 270px;
  }

  &__prev {
    left: 20px;
    background-image: url("../../vendor/img/arrow-slider-left.svg");
  }

  &__next {
    right: 20px;
    background-image: url("../../vendor/img/arrow-slider-right.svg");
  }
}

@media screen and (max-width: 1700px) {
  .swiperEvents {
    &__slide {
      width: 380px;
    }
    &__img-container {
      width: 380px;
      height: 380px;
    }

    &__description {
      font-size: 14px;
      line-height: 18px;
    }

    &__navigation {
      top: 180px;
    }

    &__title {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@media screen and (max-width: 1260px) {
  .swiperEvents {
    &__slide {
      width: 220px;
    }
    &__img-container {
      width: 220px;
      height: 220px;
    }
    &__navigation {
      display: none;
    }
  }
}
