.bread-crumbs {
  padding-top: 50px;
  padding-bottom: 27px;
  border-bottom: 1px solid #F0F0F0;
  margin-left: auto;
  margin-right: auto;
  &_shop {
    padding-top: 150px;
    margin: 0 5%;
    border-bottom: none;
  }
  &_shop-header {
    border-bottom: none;
    position: fixed;
    z-index: 1;
    top: 152px;
    margin: 0 5%;
    padding: 0;
  }
  &-link {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 16px;
    margin-right: 2px;
    text-decoration: none;
    &:not(:first-child) {
      &:before {
        content: "/";
        margin-right: 2px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .bread-crumbs {
    &_shop-header {
      top: 100px;
    }
  }
}

