.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  padding: 43px 5%;
  justify-content: space-between;
  position: relative;
  border-bottom: 2px solid #f0f0f0;
  background-color: #ffffff;
  z-index: 1;
  &_shop {
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  &__link {
    text-decoration: none;
    color: #000000;
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__navigation {
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: flex-end;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 10px;
    cursor: pointer;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-decoration: none;
    transition: transform ease 0.5s;

    &:hover {
      opacity: 0.5;
      transform: scale(1.1);
    }
  }

  &__person-popup {
    position: absolute;
    top: 70px;
    right: 4.5%;
    border: 1px solid #000000;
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 5px;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    &:hover {
      opacity: 1;
    }
    & ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 10px;
      & li {
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
        & a {
          color: #000000;
          text-decoration: none;
        }
      }
    }
  }

  &__item-menu {
    align-items: center;
  }

  &__item-text {
    margin: 0;

  }
}

@media screen and (max-width: 1700px) {
  .header {
    padding: 10px 5%;

    &__logo {
      position: static;
      transform: none;
    }

    &__item-time {
      display: none;
    }
  }
}
