.contactUs {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  &__text {
    margin: 0 0 30px 0;
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }
  &__label {
    margin-top: 20px;
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;

  }
  &__input {
    margin-top: 10px;
    padding: 15.5px 10px;
    max-width: 340px;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  &__textarea {
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    height: 100px;
    padding: 10px;
  }
  &__button {
    margin-top: 30px;
    max-width: 192px;
    background-color: #000000;
    color: #ffffff;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 20px 50px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 1);
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
}

@media screen and (max-width: 1200px) {
  .contactUs {
    max-width: 83.33%;
    margin-left: auto;
    margin-right: auto;
    &__text {
      font-size: 18px;
      line-height: 22px;
    }
    &__label {
      font-size: 18px;
      line-height: 22px;
    }
    &__input {
      font-size: 14px;
      line-height: 16px;
    }
  }
}