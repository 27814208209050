.friendsClub {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Cormorant Garamond", serif;
  margin-bottom: 50px;
  &__section {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  &__sectionTitle {
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    text-align: center;
    color: #03286B;
  }
  &__sectionDescription {
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    text-align: start;
  }
  &__sectionCardImage {
    width: 40%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
  &__sectionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 60px;
    border: 1px solid #000000;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: #ffffff;
    color: #000000;
    transition: all 0.5s;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
  &__sectionDiscountImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: auto;
  }
  &__sectionList {
    list-style-type: ' – ';
  }
  &__sectionDescriptionBold {
    font-weight: 500;
    font-size: 40px;
  }
  &__link {
    &:hover {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 700px) {
  .friendsClub {
    &__sectionTitle {
      font-size: 48px;
      line-height: 1.5;
    }
    &__sectionDescription {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 550px) {
  .friendsClub {
    &__sectionTitle {
      font-size: 32px;
      margin-top: 0;
      margin-bottom: 20px;
    }
    &__sectionDescription {
      font-size: 18px;
    }
    &__sectionDescriptionBold {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 450px) {
  .friendsClub {
    &__sectionTitle {
      font-size: 18px;
    }
    &__sectionDescription {
      font-size: 14px;
    }
    &__sectionDescriptionBold {
      font-size: 16px;
    }
    &__section {
      margin-top: 20px;
    }
  }
}
