.changePassword {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  &__block {
    display: flex;
    flex-direction: column;
  }
  &__label {
    margin-top: 20px;
    font-family: "Cormorant Garamond", serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
  }
  &__input {
    margin-top: 10px;
    padding: 15.5px 10px;
    max-width: 340px;
    font-family: Raleway, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  &__button {
    margin-top: 30px;
    max-width: 192px;
    background-color: #000000;
    color: #ffffff;
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 20px 50px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 1);
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
}